<ag-messenger
#messengerComponent
[company]="company"
[companies]="negotiation ? [negotiation.order.company, negotiation.company]: []"
[entity-id]="negotiation?.order.barter?.id || negotiation?.order.id"
entity="{{ negotiation?.order.type.id|orderType }}"></ag-messenger>

<div class="container">
  <div class="content-box">
    <div *ngIf="negotiation.status.id === 22"
    class="ribbon ribbon-right ribbon-sm ribbon-danger"><span>{{ 'GLOBAL.CANCELED'|translate }}</span></div>
    <div *ngIf="isAmendment || isCancellation"
    class="ribbon ribbon-right ribbon-sm ribbon-warning"><span>{{ 'GLOBAL.REVIEWING'|translate }}</span></div>

    <ol class="breadcrumb" *ngIf="company && negotiation">
      <li *ngIf="environment.modules.market">
        <a *ngIf="negotiation.status.id === 7 || isAmendment || isCancellation; else notBooked"
        [routerLink]="['/company', company.id, 'company-traded-orders']"
        [queryParams]="{ order_by: '-original_booking_date', 'filters[settled]': false }">{{ 'COMPANY.COMPANY_TRADED_ORDERS'|translate }}</a>
        <ng-template #notBooked>
          <a routerLink="/company/{{ company.id }}/working-orders">{{ 'COMPANY.WORKING_ORDERS'|translate }}</a>
        </ng-template>
      </li>
      <li *ngIf="negotiation.order.type.id !== 8">
        <scope-icon [entity]="negotiation.order" class="text-muted"></scope-icon>
        <a *ngIf="negotiation.order.type.id === 7 && negotiation.order.barter; else typeOrder"
        [routerLink]="['/company', company.id, 'barters', negotiation.order.barter.id]">
          <span class="hidden-xs">{{ 'BARTERS.BARTER_PROPOSAL'|translate }}</span>
          #{{ negotiation.order.barter.id }}
        </a>
        <ng-template #typeOrder>
          <a routerLink="/company/{{ company.id }}/order/{{ negotiation.order.id }}">
            <span class="hidden-xs">{{ ([4,5].includes(negotiation.order.type.id) ? 'GLOBAL.AUCTION' : 'GLOBAL.ORDER')|translate }}</span>
            #{{ negotiation.order.id }}
          </a>
        </ng-template>
      </li>
      <li class="active" *ngIf="negotiation.id">
        <span class="hidden-xs">{{
          (negotiation.status.id === 7 ? (negotiation.order.type.id === 8 ? 'GLOBAL.CERTIFICATE_DEPOSIT' : 'GLOBAL.TRADE') : 'GLOBAL.COUNTERORDER')|translate
        }}</span>
        #{{ negotiation.id }}
      </li>
      <li class="active" *ngIf="!negotiation.id">{{ 'COUNTERORDER_VIEW.NEW'|translate }}</li>
    </ol>

    <alert type="info" dismissible="true"
    *ngIf="isAmendment">
      <span [innerHtml]="'COUNTERORDER_VIEW.AMENDMENT.ALERT'|translate: {date: (negotiation.booking_date|date: ('COUNTERORDER_VIEW.BOOKING_DATE_FORMAT'|translate):undefined:currentLang)}"></span>
    </alert>
    <alert type="info" dismissible="true"
    *ngIf="isCancellation">
      <span [innerHtml]="'COUNTERORDER_VIEW.CANCELLATION.ALERT'|translate: {date: (negotiation.booking_date|date: ('COUNTERORDER_VIEW.BOOKING_DATE_FORMAT'|translate):undefined:currentLang)}"></span>
    </alert>

    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>
          {{ negotiation.order.product.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}
          <!-- FAS-1780 -->
          <span *ngIf="negotiation.id">
            <ag-assignees-manager
            *ngIf="company?.id === negotiation.company.id"
            [entity]="negotiation.entity"
            [entity-id]="negotiation.id"
            [company]="company"></ag-assignees-manager>

            <ag-assignees-manager
            *ngIf="company?.id === negotiation.order.company.id"
            [entity]="negotiation.order.entity"
            [entity-id]="negotiation.order.id"
            [company]="company"
            [editable]="false"></ag-assignees-manager>

            <span class="attach-icon" *ngIf="editorAccount">
              <input type="file" name="attach" id="attachIcon" (change)="changeFile($event)" [accept]="attachmentAccept" multiple="true">
              <label class="btn btn-link btn-sm" for="attachIcon" title="{{ 'MESSENGER.ATTACH'|translate }}"><i class="material-symbols-rounded">attach_file</i></label>
            </span>
          </span>
        </h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <ng-container
            *ngIf="isAmendment; then amendmentActions; else counterorderActions">
          </ng-container>
          <ng-template #counterorderActions>
            <div class="btn-group">
              <ag-messenger-launcher
              [messenger]="messengerComponent"></ag-messenger-launcher>

              <ng-container
                *ngIf="isNew; then newCounterorder; else existingCounterorder">
              </ng-container>
              <ng-template #newCounterorder>
                <button type="button"
                class="btn btn-link" (click)="reject()"
                [disabled]="!editorAccount"
                *ngIf="negotiation.status.id !== 12">
                  {{ 'GLOBAL.CANCEL'|translate }}
                </button>
              </ng-template>
              <ng-template #existingCounterorder>
                <button type="button"
                class="btn btn-link" (click)="reject()"
                [disabled]="sending || !proposalDetail.form.pristine || isRepresented ||
                !editorAccount"
                *ngIf="isActive">
                  <span *ngIf="hasTurn">{{ 'GLOBAL.REJECT'|translate }}</span>
                  <span *ngIf="!hasTurn">{{ 'GLOBAL.WITHDRAW'|translate }}</span>
                </button>
              </ng-template>

              <!-- Negotiation request -->
              <button type="button"
              *ngIf="negotiation.status.id === 12"
              class="btn btn-link"
              (onConfirm)="reject()"
              [confirm-options]="{
                title: 'COUNTERORDER_VIEW.NEGOTIATION_REQUEST_REJECT.TITLE'|translate,
                body: '',
                action: 'GLOBAL.YES_REJECT'|translate
              }"
              [disabled]="sending || isNew || !isActive || !proposalDetail.form.pristine || (negotiation.status.id === 12 && !hasTurn) || isRepresented ||
              !editorAccount">{{ (hasTurn ? 'GLOBAL.REJECT' : 'GLOBAL.WITHDRAW')|translate }}</button>
            </div>

            <div class="btn-group">
              <button type="button"
              class="btn btn-default" (click)="send()"
              [disabled]="sending || !canEdit || proposalDetail.form.pristine || proposalDetail.form.invalid ||
              !editorAccount">
                <i class="material-symbols-rounded hidden-xs hidden-sm">reply</i> {{ 'GLOBAL.SEND'|translate }}
              </button>

              <button type="button"
              title="{{ 'COUNTERORDER_VIEW.BOOK'|translate }}"
              class="btn btn-agree"
              (click)="accept()"
              [disabled]="sending || isNew || !hasTurn || !proposalDetail.form.pristine || proposalDetail.form.invalid ||
              !editorAccount"
              *ngIf="negotiation.status.id !== 12 && negotiation.status.id !== 20 && negotiation.status.id !== 21">
                <i class="material-symbols-rounded hidden-xs hidden-sm">done</i> {{ 'COUNTERORDER_VIEW.BOOK'|translate }}
              </button>

              <button type="button"
              class="btn btn-agree"
              *ngIf="[20, 21].includes(negotiation.status.id)"
              (onConfirm)="accept()"
              [confirm-options]="{
                title: 'COUNTERORDER_VIEW.CANCELLATION.ACCEPT.TITLE'|translate,
                body: 'COUNTERORDER_VIEW.CANCELLATION.ACCEPT.BODY'|translate,
                action: 'GLOBAL.YES_ACCEPT'|translate
              }"
              [disabled]="sending || isNew || !hasTurn ||
              !editorAccount">{{ 'COUNTERORDER_VIEW.CANCELLATION.ACCEPT.BUTTON'|translate }}</button>

              <button type="button"
              class="btn btn-agree"
              *ngIf="negotiation.status.id === 12"
              (onConfirm)="accept()"
              [confirm-options]="{
                title: 'COUNTERORDER_VIEW.NEGOTIATION_REQUEST_CONFIRM.TITLE'|translate,
                body: 'COUNTERORDER_VIEW.NEGOTIATION_REQUEST_CONFIRM.BODY'|translate,
                action: 'GLOBAL.YES_ACCEPT'|translate
              }"
              [disabled]="!hasTurn ||
              !editorAccount">{{ 'GLOBAL.ACCEPT'|translate }}</button>
            </div>
          </ng-template>
          <ng-template #amendmentActions>
            <div class="btn-group">
              <ag-messenger-launcher
              [messenger]="messengerComponent"></ag-messenger-launcher>

              <button type="button"
              class="btn btn-link"
              (click)="cancelAmendment()"
              [disabled]="sending ||
              !editorAccount"
              *ngIf="isNew">
                {{ 'GLOBAL.CANCEL'|translate }}
              </button>

              <button type="button"
              class="btn btn-link"
              (onConfirm)="rejectAmendment()"
              [confirm-options]="{
                title: 'COUNTERORDER_VIEW.AMENDMENT.REJECT.TITLE'|translate,
                body: 'COUNTERORDER_VIEW.AMENDMENT.REJECT.BODY'|translate,
                action: 'GLOBAL.YES_REJECT'|translate
              }"
              [disabled]="sending || !hasTurn || !proposalDetail.form.pristine ||
              !editorAccount"
              *ngIf="!isNew">
                {{ 'GLOBAL.REJECT'|translate }}
              </button>
            </div>

            <div class="btn-group">
              <button type="button"
              class="btn btn-default" (click)="sendAmendment()"
              [disabled]="sending || !canEdit || proposalDetail.form.pristine || proposalDetail.form.invalid ||
              !editorAccount">
                <i class="material-symbols-rounded hidden-xs hidden-sm">reply</i> {{ 'COUNTERORDER_VIEW.AMENDMENT.SEND'|translate }}
              </button>

              <button type="button"
              class="btn btn-agree"
              (onConfirm)="acceptAmendment()"
              [confirm-options]="{
                title: 'COUNTERORDER_VIEW.AMENDMENT.ACCEPT.TITLE'|translate,
                body: 'COUNTERORDER_VIEW.AMENDMENT.ACCEPT.BODY'|translate,
                action: 'GLOBAL.YES_ACCEPT'|translate
              }"
              [disabled]="sending || isNew || !hasTurn || !proposalDetail.form.pristine ||
              !editorAccount">
                {{ 'COUNTERORDER_VIEW.AMENDMENT.ACCEPT.BUTTON'|translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row"
    *ngIf="negotiation">
      <div class="col-xs-12">
        <span *ngIf="negotiation.proposal.business_detail.sustainable"
        class="ag-label label-success"><span class="glyphicon glyphicon-leaf" aria-hidden="true"></span> {{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</span>
        <span *ngFor="let label of negotiation.labels"
        class="ag-label" [style.backgroundColor]="label.color">{{ label.name }}</span>
      </div>
    </div>
    <!-- Counter order status row -->
    <div class="row" *ngIf="!sending && company && !isNew">
      <div class="col-xs-12 status">
        <div class="alert status-warning" role="alert" *ngIf="negotiation.order.on_hold">
          <i class="material-symbols-rounded">new_releases</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.PAUSED'|translate"></span>
        </div>
        <ng-container
          *ngIf="isAmendment; then amendmentStatus; else counterorderStatus">
        </ng-container>
        <ng-template #counterorderStatus>
          <ng-container *ngIf="isCancellation">
            <ng-container *ngIf="isRepresented; else cancellationDirection">
              <div class="alert status-info" role="alert" *ngIf="[20, 21].includes(negotiation.status.id)">
                <i class="material-symbols-rounded">send</i>&nbsp;
                <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.CANCELLATION.IN_PROGRESS'|translate"></span>
              </div>
            </ng-container>
            <ng-template #cancellationDirection>
              <div class="alert status-info" role="alert" *ngIf="[20, 21].includes(negotiation.status.id) && !hasTurn">
                <i class="material-symbols-rounded">send</i>&nbsp;
                <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.CANCELLATION.SENT'|translate"></span>
              </div>
              <div class="alert status-warning" role="alert" *ngIf="[20, 21].includes(negotiation.status.id) && hasTurn">
                <i class="material-symbols-rounded blinking">warning</i>&nbsp;
                <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.CANCELLATION.RECEIVED'|translate"></span>
              </div>
            </ng-template>
          </ng-container>
          <div class="alert status-info" role="alert" *ngIf="(negotiation.status.id === 1 || negotiation.status.id === 2) && !hasTurn">
            <i class="material-symbols-rounded">send</i>&nbsp;
            <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.SENT'|translate"></span>
          </div>
          <div class="alert status-warning" role="alert" *ngIf="(negotiation.status.id === 1 || negotiation.status.id === 2) && hasTurn">
            <i class="material-symbols-rounded blinking">warning</i>&nbsp;
            <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.RECEIVED'|translate"></span>
          </div>
        </ng-template>
        <ng-template #amendmentStatus>
          <ng-container *ngIf="isRepresented; else amendmentDirection">
            <div class="alert status-info" role="alert" *ngIf="[1, 2].includes(negotiation.status.id)">
              <i class="material-symbols-rounded">send</i>&nbsp;
              <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.AMENDMENT.IN_PROGRESS'|translate"></span>
            </div>
          </ng-container>
          <ng-template #amendmentDirection>
            <div class="alert status-info" role="alert" *ngIf="[1,2].includes(negotiation.status.id) && !hasTurn">
              <i class="material-symbols-rounded">send</i>&nbsp;
              <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.AMENDMENT.SENT'|translate"></span>
            </div>
            <div class="alert status-warning" role="alert" *ngIf="[1,2].includes(negotiation.status.id) && hasTurn">
              <i class="material-symbols-rounded blinking">warning</i>&nbsp;
              <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.AMENDMENT.RECEIVED'|translate"></span>
            </div>
          </ng-template>
        </ng-template>
        <div class="alert status-warning" role="alert" *ngIf="negotiation.status.id === 3 && hasTurn">
          <i class="material-symbols-rounded blinking">warning</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.PREBOOK.RECEIVED'|translate"></span>
        </div>
        <div class="alert status-info" role="alert" *ngIf="negotiation.status.id === 3 && !hasTurn">
          <i class="material-symbols-rounded">send</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.PREBOOK.REQUESTED'|translate"></span>
        </div>
        <div class="alert status-danger" role="alert" *ngIf="negotiation.status.id === 5">
          <i class="material-symbols-rounded">highlight_off</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.CANCELED'|translate"></span>
        </div>
        <div class="alert status-danger" role="alert" *ngIf="negotiation.status.id === 6">
          <i class="material-symbols-rounded">highlight_off</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.REJECTED'|translate"></span>
        </div>
        <div class="alert status-success" role="alert" *ngIf="negotiation.status.id === 7">
          <div class="flex-center-distributed">
            <div class="grow">
              <i class="material-symbols-rounded">check_circle_outline</i>&nbsp;
              <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.BOOKED'|translate"></span>&nbsp;
              <small>{{ negotiation.original_booking_date|date: ('COUNTERORDER_VIEW.BOOKING_DATE_FORMAT'|translate) }}</small>
            </div>
            <negotiation-actions
            [negotiation]="negotiation"
            [company]="company"
            (saveReference)="reload()"></negotiation-actions>
          </div>
        </div>
        <div class="alert status-danger" role="alert" *ngIf="negotiation.status.id === 8">
          <i class="material-symbols-rounded">highlight_off</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.PREBOOK.REJECTED'|translate"></span>
        </div>
        <div class="alert status-info" role="alert" *ngIf="negotiation.status.id === 9">
          <i class="material-symbols-rounded">send</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.DISCLOSURE.SENT'|translate"></span>
        </div>
        <div class="alert status-warning" role="alert" *ngIf="negotiation.status.id === 10">
          <i class="material-symbols-rounded blinking">warning</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.DISCLOSURE.RECEIVED'|translate"></span>
        </div>
        <div class="alert status-info" role="alert" *ngIf="negotiation.status.id === 11">
          <i class="material-symbols-rounded">send</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.DISCLOSURE.ANSWERED'|translate"></span>
        </div>
        <div class="alert status-info" role="alert" *ngIf="negotiation.status.id === 12 && !hasTurn">
          <i class="material-symbols-rounded">send</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.NEGOTIATION_REQUEST.SENT'|translate"></span>
        </div>
        <div class="alert status-warning" role="alert" *ngIf="negotiation.status.id === 12 && hasTurn">
          <i class="material-symbols-rounded blinking">warning</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.NEGOTIATION_REQUEST.RECEIVED'|translate"></span>
        </div>
        <div class="alert status-info" role="alert" *ngIf="negotiation.status.id === 13 && !hasTurn">
          <i class="material-symbols-rounded">send</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.NEGOTIATION_REQUEST.ACCEPTED'|translate"></span>
        </div>
        <div class="alert status-warning" role="alert" *ngIf="negotiation.status.id === 13 && hasTurn">
          <i class="material-symbols-rounded blinking">warning</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.NEGOTIATION_REQUEST.ACCEPTED'|translate"></span>
        </div>
        <div class="alert status-danger" role="alert" *ngIf="negotiation.status.id === 14">
          <i class="material-symbols-rounded">highlight_off</i>&nbsp;
          <span [innerHtml]="'COUNTERORDER_VIEW.STATUS.NEGOTIATION_REQUEST.REJECTED'|translate"></span>
        </div>
      </div>
    </div>

    <counterparties *ngIf="negotiation.company?.id" [negotiation]="negotiation" [company]="company"></counterparties>

    <proposal-detail
    [proposal]="negotiation.proposal"
    [negotiation]="negotiation"
    [previousProposal]="negotiation.previous_proposal"
    [previousContract]="negotiation.previous_proposal ? negotiation.previous_proposal.contract_detail : negotiation.order.product_clauses"
    [order]="negotiation.order"
    [negotiableMode]="getNegotiableMode()"
    [editable]="canEdit && !sending"
    [direction]="isFirst? '': incoming? 'incoming': outgoing? 'outgoing': ''"
    [formMode]="formMode"
    [chambers]="chambers"
    [isAmendment]="isAmendment"
    [hideUndefined]="negotiation.order.type.id === 8"
    #proposalDetail="proposalDetail"></proposal-detail>

    <!-- FAS-2041 -->
    <div *ngIf="negotiation?.id && company" class="row">
      <div class="col-xs-12">
        <attachments
        [disabled]="!editorAccount"
        entity="negotiation"
        [entity-id]="negotiation?.id"
        [company]="company"
        [accept]="attachmentAccept"
        [attach]="filesToAttach"
        drop-area=".app-content"
        [allowPrivate]="true"
        (onFileAction)="reload()"></attachments>
      </div>
    </div>

    <!-- FAS-3027 -->
    <div *ngIf="negotiation?.id && company && environment.modules.signatures && company?.hasModule('signatures')" class="row">
      <div class="col-xs-12">
        <ag-related-envelopes
        entity="negotiation"
        [entity-id]="negotiation.id"
        [disabled]="sending"
        [company]="company"></ag-related-envelopes>
      </div>
    </div>

    <div *ngIf="negotiation?.linked_negotiations?.length && company" class="row">
      <div class="col-xs-12">
        <div class="flex-center-distributed">
          <h4>{{ 'COUNTERORDER_VIEW.LINKED'|translate }}
            <button type="button"
            class="btn btn-link btn-xs"
            (click)="hideRelated = !hideRelated"
            [innerHtml]="(hideRelated ? 'GLOBAL.TOGGLE_SHOW' : 'GLOBAL.TOGGLE_HIDE')|translate"></button>
          </h4>

          <button type="button"
          class="btn btn-link"
          title="{{ 'NEGOTIATION_ACTIONS.LINK_NEGOTIATIONS'|translate }}"
          (click)="negotiationLinker.link(negotiation)"
          [disabled]="sending ||
          !editorAccount">
            <span class="material-symbols-rounded">link</span>
          </button>
        </div>
        <closed-negotiations-table
        *ngIf="!hideRelated"
        [company]="company"
        [negotiations]="relatedNegotiations"
        [hideColumns]="['contract', 'linker', 'unloads', 'balance', 'actions']"
        [hideFilters]="true"
        [show-unlink]="true"
        [group-by-product]="true"
        (unlink)="unlink($event)"
        (onForceReload)="reload()"></closed-negotiations-table>
      </div>
      <ag-negotiation-linker #negotiationLinker [company]="company"></ag-negotiation-linker>
    </div>

    <!-- FAS-2768 -->
    <ng-container *ngIf="company && environment.modules.slots && company?.hasModule('slots')">
      <div class="row" *ngIf="negotiation?.batches?.length">
        <slots-modals #slotsModals
        [company]="company"
        [user]="user"></slots-modals>
  
        <div class="col-xs-12">
          <div class="flex-center-distributed">
            <h4>{{ 'COMPANY.SLOTS'|translate }}</h4>
            <button type="button"
            class="btn btn-link"
            title="{{ 'NEGOTIATION_ACTIONS.ASSIGN_SLOTS'|translate }}"
            (click)="slotsModals.assignBatchToNegotiation(negotiation)"
            *ngIf="(account?.is.logistics || account?.is.commercial) && negotiation.buyers_id.includes(company.id)"
            [disabled]="sending">
              <span class="material-symbols-rounded">add</span>
            </button>
          </div>
          <p [innerHtml]="'SLOTS.ASSIGNED_TRADE'|translate: { quantity: negotiation.batches|pluck:'slots_total'|sum }"></p>
  
          <table class="table table-condensed"
          [sortable]="negotiation.batches" defaultSortBy="-date" #slotsTable="tableSortable">
            <thead>
              <tr>
                <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
                <th class="sap" sortBy="slots_total">{{ 'SLOTS.TABLE_HEADER.BATCH_SLOTS'|translate }}</th>
                <th></th>
                <th sortBy="status.id">{{ 'GLOBAL.STATUS'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-align-center" *ngFor="let batch of slotsTable.collection">
                <td class="sap small"><date-md [date]="batch.date" [style]="true"></date-md></td>
                <td class="sap text-right">
                  <samp><b>{{ batch.slots_total }}</b></samp>
                </td>
                <td><a [routerLink]="['/company', company.id, 'slots', batch.id]">{{ 'SLOTS.BATCH_DETAILS'|translate }}</a></td>
                <td>
                  <ng-container [ngSwitch]="batch.status.id">
                    <span *ngSwitchCase="1" class="text-warning">{{ 'SLOTS.STATUS.RECEIVED'|translate }}</span>
                    <span *ngSwitchCase="2" class="text-success">{{ 'SLOTS.STATUS.ACCEPTED'|translate }}</span>
                    <span *ngSwitchCase="3" class="text-danger">{{ 'SLOTS.STATUS.REJECTED'|translate }}</span>
                    <span *ngSwitchCase="4" class="text-danger">{{ 'SLOTS.STATUS.WITHDRAWN'|translate }}</span>
                    <span *ngSwitchCase="5" class="text-danger">{{ 'SLOTS.STATUS.RETURNED'|translate }}</span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <div class="row" *ngIf="negotiation?.id">
        <div class="col-xs-12">
          <div class="flex-center-distributed">
            <h4>
              {{ 'SLOTS.REQUEST_SLOT_MODAL.REQUESTED'|translate }}
              <button type="button"
              class="btn btn-link btn-xs" (click)="toggleSlotsRequested()"
              [innerHtml]="(showSlotsRequested ? 'GLOBAL.TOGGLE_HIDE' : 'GLOBAL.TOGGLE_SHOW')|translate">
              </button>
            </h4>
          </div>
        </div>
        <div class="col-xs-12" *ngIf="showSlotsRequested">
          <ag-slots-requests-table [company]="company" [compact]="true" [negotiationId]="negotiation.id">
          </ag-slots-requests-table>
        </div>
      </div>
    </ng-container>

    <trackrecord
    *ngIf="negotiation?.id"
    entity="{{ negotiation.entity }}"
    [ids]="[negotiation.id]"
    [refreshOnEvent]="refreshTrackRecord"
    [show]="negotiation.status.id !== 7"></trackrecord>
  </div>

  <ng-container *ngIf="company && negotiation && negotiation.status.id === 7">
    <div class="content-box"
    *ngIf="(contract_ids && environment.modules.contracts) ||
    (!environment.production && environment.modules.futures)"
    [class.hidden]="!tableData.contracts && !tableData.futures">
      <div
      *ngIf="contract_ids && environment.modules.contracts"
      [class.hidden]="!tableData.contracts || tableData.contracts.data.length === 0">
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <h4>{{ 'GLOBAL.CONTRACTS'|translate }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <contracts-table
            [filters]="{
              'filters[id]': 'in:' + contract_ids
            }"
            [company]="company"
            [hideColumns]="['product', 'delivery.locations', 'linker', 'source']"
            (load)="tableLoaded($event, 'contracts')"></contracts-table>
          </div>
        </div>
      </div>
      <div
      *ngIf="!environment.production && environment.modules.futures"
      [class.hidden]="!tableData.futures">
        <future-modal #futureModal
        [company]="company"></future-modal>

        <div class="ag-header sticky">
          <div class="ag-header-title">
            <h4>{{ 'FUTURES.TITLE'|translate }}</h4>
          </div>
          <div class="ag-header-actions">
            <button type="button" class="btn btn-agree"
            [disabled]="!editorAccount"
            (click)="futureModal.open()">{{ 'FUTURES.ADD'|translate }}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <futures-table
            [filters]="{
              'filter[seller.name]': 'contains:galli'
            }"
            [company]="company"
            [hideColumns]="['product', 'company']"
            (load)="tableLoaded($event, 'futures')"></futures-table>
          </div>
        </div>
      </div>
    </div>

    <div class="content-box"
    *ngIf="contract_ids && environment.modules.unloads"
    [class.hidden]="!tableData.trucks">
      <div class="ag-header sticky">
        <div class="ag-header-title">
          <h4>{{ 'TRUCKS.TITLE'|translate }}</h4>
        </div>
        <div class="ag-header-actions" *ngIf="tableData.trucks?.data && tableData.trucks.data.length > 0">
          <div class="row-summary text-right" [tooltip]="weightTemplate" placement="left" container="body">
            <div><b>{{ 'TRUCKS.APPLIED'|translate }}:</b> {{ tableData.trucks.weight.applied.value|number:'1.0-3':currentLang }} <small class="text-muted">{{ tableData.trucks.weight.applied.unit.abbrev }}</small></div>
            <div class="text-muted small" [innerHtml]="'TRUCKS.UNLOADS_NUMBER'|translate:{quantity: tableData.trucks.data.length}"></div>
          </div>
          <ng-template #weightTemplate>
            <div class="text-right">
              <div [innerHtml]="'TRUCKS.NET_KG'|translate:{quantity: (tableData.trucks.weight.net|number:'1.0-0':currentLang)}"></div>
              <div [innerHtml]="'TRUCKS.GROSS_KG'|translate:{quantity: (tableData.trucks.weight.gross|number:'1.0-0':currentLang)}"></div>
              <div [innerHtml]="'TRUCKS.TARE_KG'|translate:{quantity: (tableData.trucks.weight.tare|number:'1.0-0':currentLang)}"></div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <trucks-table
          [filters]="{
            'filters[contracts.*.id]': 'in:' + contract_ids
          }"
          [company]="company"
          [hideColumns]="['waybill.product', 'source']"
          (load)="tableLoaded($event, 'trucks')"></trucks-table>
        </div>
      </div>
    </div>

    <div class="content-box"
    *ngIf="contract_ids && environment.modules.liquidaciones"
    [class.hidden]="!tableData.liquidaciones || tableData.liquidaciones.data.length === 0">
      <div class="ag-header sticky">
        <div class="ag-header-title">
          <h4>{{ 'LIQUIDACIONES.TITLE'|translate }}</h4>
        </div>
        <div class="ag-header-actions" *ngIf="tableData.liquidaciones && tableData.liquidaciones.data?.length > 0">
          <div class="row-summary text-right">
            <b>{{ 'INVOICES.BALANCE'|translate }}:</b>&nbsp;<price unit="ARS" [value]="tableData.liquidaciones.data|pluck:'total'|sum"></price>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <ag-liquidaciones-table
          [company]="company"
          [filters]="{
            'filters[contract.id]': 'in:' + contract_ids
          }"
          [hideColumns]="['contract_reference', 'product_name', 'buyer', 'seller', 'broker', 'source']"
          (load)="tableLoaded($event, 'liquidaciones')"></ag-liquidaciones-table>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #unavailableModal>
  <!-- modal -->
  <div class="modal-header">
    <h4 class="modal-title">{{ 'COUNTERORDER_VIEW.UNAVAILABLE_MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'COUNTERORDER_VIEW.UNAVAILABLE_MODAL.BODY'|translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="btn btn-agree" (click)="modalRef.hide()" routerLink="../../working-orders">{{ 'GLOBAL.ACCEPT'|translate }}</button>
  </div>
</ng-template>
