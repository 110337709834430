import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { Company } from '../../../../../models/company.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { Negotiation } from '../../models/negotiation.model';
import { OrderService } from '../../services/order.service';

// declare const walkthrough: any;

@Component({
  selector: 'app-traded-orders',
  templateUrl: './traded-orders.component.html',
  styleUrls: ['./traded-orders.component.css']
})
export class TradedOrdersComponent implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;

  private subscriptions: Subscription[] = [];

  public company: Company;
  /** The language currently used. */
  public currentLang: string;
  public filters: Array<any>;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public loadingPrivate: boolean = true;
  public negotiationsByProduct: GroupBy<Product, Negotiation>[] = [];
  public paginationData: Pagination;
  public totalTons = 0;
  public totalTonsPrivate = 0;

  constructor(
    private companyService: CompanyService,
    private componentComm: ComponentCommService,
    private intercomService: IntercomService,
    private orderService: OrderService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.componentComm.emit({ name: 'app-title', title: 'COMPANY.TRADED_ORDERS' });

    this.intercomService.track('traded_orders-visited');
    // walkthrough('traded_orders', false, this.translateService);

    let company = this.companyService.watch().pipe(
      tap(company => {
        this.company = company;
        // Kick user if module is not enabled
        if (this.company && (!this.company.market.configuration.traded_orders.enabled || !this.company.hasModule('market'))) {
          this.router.navigateByUrl('/companies');
          return;
        }
      }),
      filter(company => !!company)
    );

    const filters = this.selectedFilters.getFilters();

    const tradedOrders = combineLatest([
      company,
      filters
    ]).pipe(
      switchMap(values => {
        this.loading = true;

        this.filters = values[1];

        return this.orderService.watchTradedOrders(values[0].id, this.filters);
      })
    );

    this.subscriptions.push(tradedOrders.subscribe(response => {
      this.parseNegotiationsResponse(response.body);
      this.paginationData = parsePagination(response.headers);
    }));

    let tradedOrdersPrivate = combineLatest([
      company,
      filters
    ]).pipe(
      switchMap(values => {
        this.loadingPrivate = true;

        this.filters = values[1];

        return this.orderService.watchTradedPrivateNegotiations(values[0].id, this.filters);
      })
    );

    this.subscriptions.push(tradedOrdersPrivate.subscribe(negotiationsPrivate => {
      if (!negotiationsPrivate) return;

      for (let i in negotiationsPrivate) {
        if (i === 'private_total_tons') {
          this.totalTonsPrivate = negotiationsPrivate[i];
        }
      }

      this.loadingPrivate = false;
    }));
  }

  private parseNegotiationsResponse(negotiationsByProduct: GroupBy<Product, Negotiation>[]): void {
    this.negotiationsByProduct = negotiationsByProduct;
    this.negotiationsByProduct.forEach(group => {
      group.values.forEach(negotiation => {
        // TODO: Temporal, eliminar
        if (!negotiation.booking_date) {
          negotiation.booking_date = negotiation.updated_at;
        }
        // negotiation.proposal.quality_string = qualityToString(negotiation.proposal.product_detail.quality, group.key);
      });
    });

    this.sumTotals();
    this.loading = false;
  }

  private sumTotals(): void {
    let total = 0;
    this.negotiationsByProduct.forEach(group => {
      group.values.forEach(negotiation => {
        if (negotiation.proposal.business_detail.quantity.value) {
          total += negotiation.proposal.business_detail.quantity.value;
        }
      });
    });
    this.totalTons = total;
  }

  // TODO: This should be a pipe
  public countryAndPort(negotiation): string {
    return negotiation.order.product.attributes.country.name + ' / ' + negotiation.proposal.business_detail.port.name;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
