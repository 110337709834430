<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
      <filter-list key="payment_condition"></filter-list>
      <filter-list key="operation_type"></filter-list>
      <filter-list key="order_type"
      *ngIf="environment.modules.auctions && company?.market.configuration.allowed_order_types.auction"></filter-list>
      <filter-list key="product_id" sortKey="name"></filter-list>
      <filter-list key="delivery_type"></filter-list>
      <filter-list key="zone_id" sortKey="name"
      *ngIf="company?.market.configuration.location.zone.enabled"></filter-list>
      <filter-select key="range"></filter-select>
    </filter-set>
  </div>
  <spinner *ngIf="processing" message="{{ 'GLOBAL.PROCESSING'|translate }}" [block-ui]="true"></spinner>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky" *ngIf="ordersByProduct?.length > 0 && !loading">
          <div class="ag-header-title">
            <!-- View mode -->
            <!-- FAS-1789 -->
            <div id="viewmode-toggle" class="btn-group" data-toggle="buttons"
            *ngIf="company?.market.configuration.order.allow_media || hasOrdersWithGeolocation">
              <!-- <label class="btn btn-default btn-sm" [class.active]="viewMode === 'expanded'" title="{{ 'WORKING_ORDERS.VIEWMODE.EXPANDED'|translate }}">
                <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="expanded">
                <i class="material-symbols-rounded">view_agenda</i>
              </label> -->
              <label class="btn btn-default btn-sm" [class.active]="viewMode === 'gallery'" title="{{ 'WORKING_ORDERS.VIEWMODE.GALLERY'|translate }}"
              *ngIf="company?.market.configuration.order.allow_media">
                <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="gallery">
                <i class="material-symbols-rounded">view_module</i>
              </label>
              <label class="btn btn-default btn-sm" [class.active]="viewMode === 'table'" title="{{ 'WORKING_ORDERS.VIEWMODE.TABLE'|translate }}">
                <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="table">
                <i class="material-symbols-rounded">view_list</i>
              </label>
              <label class="btn btn-default btn-sm" [class.active]="viewMode === 'map'" title="{{ 'WORKING_ORDERS.VIEWMODE.MAP'|translate }}"
              *ngIf="hasOrdersWithGeolocation && (environment.maps.mapbox || environment.maps.google)">
                <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="map">
                <i class="material-symbols-rounded">map</i>
              </label>&nbsp;&nbsp;
            </div>
            <div class="summary" *ngIf="!selection.length">
              <span [innerHtml]="'WORKING_ORDERS.SUMMARIZE'|translate: {quantity: (ordersByProduct|pluck:'values'|flatten).length}"></span>
              <span *ngIf="company?.market.quantity_units.length === 1 && totalTons > 0"
              [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                quantity: totalTons|number:undefined:currentLang,
                unit: company?.market.quantity_units[0].abbrev
              }"></span>
            </div>
            <div class="summary" *ngIf="selection.length > 0">
              <span [innerHtml]="'WORKING_ORDERS.SELECTED'|translate: {quantity: selection.length}"></span>
              <span *ngIf="company?.market.quantity_units.length === 1"
              [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                quantity: selection|pluck:'business_detail.quantity.value'|sum|number:undefined:currentLang,
                unit: company?.market.quantity_units[0].abbrev
              }"></span>
              &mdash;
              <a class="small" (click)="clearSelection()">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
            </div>
          </div>
          <div class="ag-header-actions">
            <button type="button" class="btn btn-link btn-xs" [disabled]="processing"
            title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}"
            (click)="copy()">
              <span class="material-symbols-rounded">content_copy</span>
            </button>
            <button type="button"
            title="{{ 'WORKING_ORDERS.BOOK'|translate }}" class="btn btn-agree"
            [disabled]="!selection.length || processing" (click)="massiveBook()" *ngIf="company?.market.configuration.order.massive_book?.enabled">
              <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
              {{ 'WORKING_ORDERS.BOOK'|translate }}
            </button>
          </div>
        </div>

        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <!-- No data -->

            <div class="no-data" *ngIf="ordersByProduct?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'ORDER.NO_RECORDS'|translate }}
            </div>

            <orders-table *ngIf="viewMode === 'table'"
            [company]="company"
            [orders]="ordersByProduct"
            [(ngModel)]="selection"
            [enableSelection]="company?.market.configuration.order.massive_book?.enabled"
            [market]="company.market"></orders-table>
    
            <div class="gallery-container" *ngIf="viewMode === 'gallery'" AgMultiselection [(ngModel)]="selection">
              <ng-container *ngFor="let productGroup of ordersByProduct">
                <div class="gallery-item" *ngFor="let order of productGroup.values" (click)="gotoOrder(order.id)">
                  <div class="gallery-card">
                    <div class="thumbs">
                      <media-browser [order-media]="order.media"></media-browser>
                      <!-- <img src="assets/img/sample/prod01.jpg"> -->
                      <div class="icons">
                        <!-- Scope -->
                        <scope-icon [entity]="order"></scope-icon>
                        <!-- Auction -->
                        <div class="material-symbols-rounded" tooltip="{{ 'GLOBAL.AUCTION'|translate }}" placement="right" *ngIf="order.auction">gavel</div>
                      </div>
                      <!-- Price -->
                      <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                        <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                        <div class="value">
                          <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                        </div>
                      </div>
                      <!-- Select -->
                      <label (click)="$event.stopImmediatePropagation()" class="select-card" *ngIf="company?.market.configuration.order.massive_book?.enabled && order.operation_type === 'venta' && order.company?.id && companyId !== order.company?.id">
                        <input [AgCheckbox]="order" type="checkbox" id="order_id_{{ order.id }}_check">
                      </label>
                    </div>
                    <!-- Quantity -->
                    <quantity-control *ngIf="!company?.market.configuration.order.has_minimum_order" class="pull-right" [quantity]="order.business_detail.quantity"></quantity-control>
                    <div>
                      <b>{{ order.product.name }}</b>
                    </div>
                    <!-- Quality -->
                    <p class="small text-muted subtitle">
                      <span class="truncate">{{ order.quality_string }}</span>
                      <!-- Comments -->
                      <i class="material-symbols-rounded" *ngIf="order.general_observations"
                      [tooltip]="order.general_observations|stripHtml">comment</i>
                    </p>
                    <div class="company-data flex-center-distributed">
                      <div><!-- Company logo -->
                        <company-logo [company]="order.company"></company-logo>
                      </div>
                      <div class="grow truncate"><!-- Company Name -->
                        <span *ngIf="!order.id" class="not-available" title="{{ 'WORKING_ORDERS.UNAUTHORIZED'|translate }}">***</span>
                        <span *ngIf="order.id" title="{{ order.companyName }}">
                          <samp class="hidden-xs hidden-sm small">#{{ order.id }}:</samp>
                          <b [class.highlighted-text]="companyId === order.company.id">{{ order.companyName }}</b>
                        </span>                        
                      </div>
                      <div>
                        <flag [country]="order.company?.address.country"></flag>
                      </div>
                    </div>
                    <div class="delivery-data flex-center-distributed">
                      <div class="small grow"*ngIf="order.business_detail.delivery.locations?.length" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">{{ order.business_detail.delivery.locations |locationString }}</div>
                      <div class="small grow truncate"*ngIf="order.business_detail.delivery.geolocations" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">
                        <ng-container *ngFor="let location of order.business_detail.delivery.geolocations; let last = last">
                          {{ location.name }}<span *ngIf="!last">, </span>
                        </ng-container>
                      </div>
                      <div class="small grow" *ngIf="company?.market.configuration.order.business_detail.port.enabled" title="{{ 'GLOBAL.COUNTRY_PORT'|translate }}">{{ countryAndPort(order) }}</div>
                      <div class="text-right grow" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" title="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">
                        <b>{{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}</b>
                      </div>
                      <div title="{{ 'GLOBAL.PERIOD'|translate }}">
                        <!-- Delivery period -->
                        <date-range
                        [showSpot]="true"
                        [from]="order.business_detail.delivery.date_from"
                        [to]="order.business_detail.delivery.date_to"></date-range>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- FAS-1822 -->
            <div class="expanded-container" *ngIf="viewMode === 'expanded'" AgMultiselection [(ngModel)]="selection">
              <ng-container *ngFor="let productGroup of ordersByProduct">
                <div class="expanded-item" [class.clickable]="order.id" *ngFor="let order of productGroup.values" routerLink="../order/{{ order.id }}">
                  <div class="main-col">
                    <div class="head">
                      <span *ngIf="order.id" class="pull-right">
                        <span class="small text-muted hidden-xs hidden-sm"><b>{{ 'GLOBAL.ORDER'|translate }}:</b> #{{ order.id }}</span>
                        <!-- Select -->
                        <label (click)="$event.stopImmediatePropagation()" class="select-expanded" *ngIf="company?.market.configuration.order.massive_book?.enabled && order.operation_type === 'venta' && order.company?.id && companyId !== order.company?.id">
                          <input [AgCheckbox]="order" type="checkbox" id="order_id_{{ order.id }}_check">
                        </label>
                      </span>
                      <b class="bigger">{{ order.product.name }}</b>
                      <span class="text-muted">
                        <small>- {{ order.quality_string }}</small>
                        <!-- Scope -->
                        <scope-icon [entity]="order"></scope-icon>
                        <!-- Auction -->
                        <div class="material-symbols-rounded" tooltip="{{ 'GLOBAL.AUCTION'|translate }}" placement="right" *ngIf="order.auction">gavel</div>
                      </span>
                    </div>
                    <div class="details">
                      <!-- Quantity -->
                      <div class="pull-right text-right hidden-sm hidden-xs">
                        <div class="field-label">{{ 'GLOBAL.QUANTITY'|translate }}</div>
                        <quantity-control class="bigger" [quantity]="order.business_detail.quantity"></quantity-control>
                      </div>
                      <div class="flex-container">
                        <div class="comp-col">
                          <div class="pull-right text-right visible-sm visible-xs">
                            <div class="field-label">{{ 'GLOBAL.QUANTITY'|translate }}</div>
                            <quantity-control [quantity]="order.business_detail.quantity"></quantity-control>
                          </div>
                          <div class="company-data flex-center-distributed">
                            <div><!-- Company logo -->
                              <company-logo [company]="order.company"></company-logo>
                            </div>
                            <div class="grow truncate"><!-- Company Name -->
                              <span *ngIf="!order.id" class="not-available" title="{{ 'WORKING_ORDERS.UNAUTHORIZED'|translate }}">***</span>
                              <span *ngIf="order.id" title="{{ order.companyName }}">
                                <samp class="hidden-xs hidden-sm small">#{{ order.id }}:</samp>
                                <b [class.highlighted-text]="companyId === order.company.id">{{ order.companyName }}</b>
                              </span>                        
                            </div>
                            <div>
                              <flag [country]="order.company?.address.country"></flag>
                            </div>
                          </div>
                          <div class="thumbs">
                            <media-browser [order-media]="order.media"></media-browser>
                          </div>
                        </div>
                        <div class="specs-col">
                          <table class="table table-condensed">
                            <tbody>
                              <!-- Delivery period -->
                              <tr>
                                <td class="field-label">{{ 'GLOBAL.PERIOD'|translate }}:</td>
                                <td class="text-capitalize">
                                  {{ order.business_detail.delivery.date_from|date: ('GLOBAL.DATE_FORMAT.XS'|translate):undefined:currentLang }}
                                  {{ order.business_detail.delivery.date_from|date: 'yyyy' }}
                                  <i class="material-symbols-rounded text-muted">play_arrow</i>
                                  {{ order.business_detail.delivery.date_to|date: ('GLOBAL.DATE_FORMAT.XS'|translate):undefined:currentLang }}
                                  {{ order.business_detail.delivery.date_to|date: 'yyyy' }}
                                </td>
                              </tr>
                              <!-- Delivery places -->
                              <tr *ngIf="company?.market.configuration.location.enabled">
                                <td class="field-label">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}:</td>
                                <td>
                                  {{ order.business_detail.delivery.locations |locationString }}
                                  <span class="small" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                                    <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}:</abbr>
                                    {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
                                  </span>
                                  <span class="small text-muted" *ngIf="order.business_detail.delivery.locations?.length > 1 || order.business_detail.delivery.locations[0].zone">
                                    ({{ 'WORKING_ORDERS.BUYER_OPTION'|translate }})
                                  </span>
                                </td>
                              </tr>
                              <!-- Ports -->
                              <tr *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                                <td class="field-label">{{ 'GLOBAL.COUNTRY_PORT'|translate }}:</td>
                                <td>
                                  {{ countryAndPort(order) }}
                                  <span class="small" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                                    <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}:</abbr>
                                    {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
                                  </span>
                                </td>
                              </tr>
                              <!-- Haverst -->
                              <tr *ngIf="order.business_detail.harvest">
                                <td class="field-label">{{ 'GLOBAL.CROP_YEAR'|translate }}:</td>
                                <td>{{ order.business_detail.harvest }}</td>
                              </tr>
                              <!-- Chamber -->
                              <tr *ngIf="order.arbitration_chamber">
                                <td class="field-label">{{ 'WORKING_ORDERS.ARBITRATION_CHAMBER'|translate }}:</td>
                                <td>{{ order.arbitration_chamber.name }}</td>
                              </tr>
                              <!-- Observations -->
                              <tr *ngIf="order.general_observations">
                                <td class="field-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}:</td>
                                <td [innerHtml]="order.general_observations"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="side-col">
                    <!-- Price -->
                    <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                      <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                      <div class="value">
                        <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                      </div>
                    </div>
                    <div class="condition small">{{ order.payment_detail.payment_condition.name }}</div>
                    <button type="button"
                    *ngIf="order.id && companyId !== order.company.id"
                    (click)="$event.stopImmediatePropagation()" routerLink="../order/{{ order.id }}" [queryParams]="{ 'messenger': 'open' }"
                    class="btn btn-default btn-block btn-sm">
                      <i class="material-symbols-rounded">chat</i>
                      <span class="hidden-xs hidden-sm">{{ 'WORKING_ORDERS.CHAT'|translate }}</span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- FAS-1791 -->
            <div class="map-container" *ngIf="viewMode === 'map' && ordersByProduct?.length > 0" AgMultiselection [(ngModel)]="selection">
              <mapbox-view
                *ngIf="environment.maps.mapbox"
                [company]="company"
                [companyId]="companyId"
                [ordersByProduct]="ordersByProduct"></mapbox-view>
              <gmap-view
                *ngIf="environment.maps.google"
                [company]="company"
                [companyId]="companyId"
                [ordersByProduct]="ordersByProduct"></gmap-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO: Analyze how to replace with the confirm directive -->
<agree-confirm
  title="{{ confirmTitle }}"
  body="{{ confirmBody }}"
  confirm-button="{{ confirmButton }}"
  cancel-button="{{ cancelButton }}"
  #agreeConfirm="agreeConfirm"
>
</agree-confirm>
